<template>
  <div>
    <page-header name="Minha Conta"/>
    <b-tabs
      horizontal
      content-class="col-12 col-md-12 mt-1 mt-md-0"
      nav-wrapper-class="col-md-12 col-12"
      nav-class="nav-left"
    >

      <!-- general tab -->
      <b-tab active>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Meus Dados</span>
        </template>

        <account-setting-general />
      </b-tab>
      <!--/ general tab -->

      <!-- change password tab -->
      <b-tab>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Segurança</span>
        </template>

        <account-setting-password />
      </b-tab>
    <!--/ change password tab -->
    </b-tabs>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AccountSettingGeneral from './AccountSettingsGeneral.vue'
import AccountSettingPassword from './AccountSettingsPassword.vue'

export default {
  components: {
    AccountSettingGeneral,
    AccountSettingPassword,
  },
  data() {
    return {
      options: {},
    }
  },
  methods: {
    ...mapActions('auth', ['me']),
  },
}
</script>
